/* eslint-disable @typescript-eslint/no-use-before-define */

import React, { useEffect, useState } from 'react';
import { useQueryParam, StringParam } from 'use-query-params';
import isEmpty from 'lodash/isEmpty';

import SortBar from '@components/SortBar';
import { MainMenu } from '@components/MainMenu';
import { PolicyCardsSearch } from '@components/Policies/PolicyCardsSearch';
import { NoCardMatchesFoundMessage } from '@components/NoCardMatchesFoundMessage';
import { PolicyList } from '@components/Policies/PolicyList';
import { SuppliersCaveat } from '@components/SuppliersCaveat';
import { InlineContainer } from '@components/InlineContainer';

import * as contentful from 'types/contentful';
import { Item } from '@components/SortBar/types';
import { getPolicyCardsSearchData } from '@components/Policies/PolicyCardsSearch/helpers';
import { IPageProps } from '../types';

const sortByItem: Item[] = [
  {
    label: 'Alphabetically',
    fieldName: 'airportName',
  },
  {
    label: 'Last updated',
    fieldName: 'updatedAt',
  },
];

const PolicyAirportsPage = (props: IPageProps<contentful.Airport[]>): React.ReactElement => {
  const {
    uri,
    navigate,
    pageContext,
    location: { pathname, search },
  } = props;
  const [filterData, setFilterData] = useState<contentful.Airport[]>([]);
  const [emptyItemsMessageVisible, setEmptyItemsMessageVisibile] = useState(false);
  const [sortBySelectedOption, setSortBySelectedOption] = useState(sortByItem[0]);

  const [code, setCode] = useQueryParam('code', StringParam);

  const displayFilterData = (data: contentful.Airport[]): void => {
    setEmptyItemsMessageVisibile(false);
    setFilterData(data);
  };

  const handleSortSelect = (data: contentful.Airport[], selectedOption: Item): void => {
    setSortBySelectedOption(selectedOption);
    setFilterData(data);
  };

  const handleSearchChange = (data: contentful.Airport[], isResetData: boolean): void => {
    if (isResetData) {
      displayFilterData(pageContext.data);

      return;
    }

    if (isEmpty(data)) {
      setEmptyItemsMessageVisibile(true);
    } else {
      displayFilterData(data);
    }
  };

  const data = isEmpty(filterData) ? pageContext.data : filterData;

  useEffect(() => {
    if (code) {
      props.navigate(`${pathname.replace(/\/$/, '')}/${search}`);

      handleSearchChange(
        getPolicyCardsSearchData({
          data,
          value: code || '',
          searchFields: ['airportCode'],
          filterByFieldName: 'airportName',
        }),
        false,
      );
    }
  }, [code]);

  return (
    <>
      <MainMenu uri={uri} navigate={navigate} staticUri={pathname} />
      <InlineContainer>
        <PolicyCardsSearch
          handleValueChange={handleSearchChange}
          data={pageContext.data}
          searchFields={['airportName', 'airportCode', 'country.countryName', 'city.cityName']}
          filterByFieldName="airportName"
          setCode={setCode}
        />
        <SortBar
          data={data}
          options={sortByItem}
          handleSortSelect={handleSortSelect}
          selectedOption={sortBySelectedOption}
        />
        <SuppliersCaveat />
      </InlineContainer>
      {emptyItemsMessageVisible ? (
        <NoCardMatchesFoundMessage />
      ) : (
        <PolicyList
          getItemProps={item => ({
            items: [
              {
                content: item.faceMasks?.json,
                title: 'Face Masks',
                classImg: 'faceMasksImg',
              },
              {
                content: item.cleaningPolicy?.json,
                title: 'Cleaning Policy',
                classImg: 'cleaningPolicyImg',
              },
              {
                content: item.socialDistancing?.json,
                title: 'Social Distancing',
                classImg: 'socialDistancingImg',
              },
              {
                content: item.covidTestFacility?.json,
                title: 'COVID Test Facility',
                classImg: 'covidTestImg',
              },
              {
                content: item.healthChecksDocuments?.json,
                title: 'Health Checks',
                classImg: 'healthChecksImg',
              },
              {
                content: item.otherInformation?.json,
                title: 'Other Information',
                classImg: 'otherInformationImg',
              },
            ],
            title: formatTitle(item),
            onsiteTestingFacility: item.onsiteTestingFacility,
            updatedAt: item.updatedAt,
            link: item.airportUrl
              ? { url: item.airportUrl.link, label: item.airportUrl.label }
              : undefined,
            key: item.id,
          })}
          data={data}
        />
      )}
    </>
  );
};

function formatTitle(item: contentful.Airport) {
  const details = [item.airportCode];
  if (item.city) {
    details.push(item.city.cityName);
  }
  if (item.country) {
    details.push(item.country.countryName);
  }

  return `${item.airportName} (${details.join(', ')})`;
}

export default PolicyAirportsPage;
